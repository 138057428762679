import { render, staticRenderFns } from "./TambahProdukKompack.vue?vue&type=template&id=775b1232&scoped=true&"
import script from "./TambahProdukKompack.vue?vue&type=script&lang=js&"
export * from "./TambahProdukKompack.vue?vue&type=script&lang=js&"
import style0 from "./TambahProdukKompack.vue?vue&type=style&index=0&id=775b1232&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775b1232",
  null
  
)

export default component.exports